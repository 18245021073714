import React from "react";
import { useSelector } from "react-redux";
import Loading from "components/shared/loader/loader";
import SmsVerificationDialog from "components/public-dashboard/features/sms_verification";
import SignupForm from "./signup_form";

export default function Signup(props) {
  const {
    onSubmit,
    onChangeFormField,
    onChangeFormFieldPhone,
    validateCheckBox,
    isChecked,
    isVisible,
    countries,
    mobNum,
    isRegDisable,
    error,
    tosError,
    setVisibility,
    onOtpVarify,
    otpError,
    resendOtp,
    tokens,
    setMobileOtp,
    password,
    confirmPwd,
    timeoutDuration

  } = props;

  const loading = useSelector((state) => state.uiGlobal.loading);
  return (
    <div>
      {loading && <Loading />}
      {isVisible && (
        <SmsVerificationDialog
          open={isVisible}
          mobile={mobNum}
          smsOnly={true}
          timeoutDuration={timeoutDuration}
          handleClose={setVisibility}
          setMobileOtp={setMobileOtp}
          onOtpVarify={onOtpVarify}
          resendOtp={resendOtp}
          error={otpError}
          title="Sign Up"
          title2="Verification"
        />
      )}
      <SignupForm
        onSubmit={onSubmit}
        onChangeFormField={onChangeFormField}
        onChangeFormFieldPhone={onChangeFormFieldPhone}
        validateCheckBox={validateCheckBox}
        isChecked={isChecked}
        countries={countries}
        mobNum={mobNum}
        isRegDisable={isRegDisable}
        error={error}
        tosError={tosError}
        confirmPwd={confirmPwd}
        password={password}
      />
    </div>
  );
}
