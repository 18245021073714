export const API_ROOT = process.env.REACT_APP_API_URL;
export const DOCUMENT_API = `${API_ROOT}/document`

export const AUTH = "AUTH";
export const TOKEN = "TOKEN";
export const ANONTOKEN = "ANONTOKEN";
export const USER = "USER";

export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";

export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAILURE = "SIGNUP_FAILURE";

export const OTP_VARIFY_REQUEST = "OTP_VARIFY_REQUEST";
export const OTP_VARIFY_SUCCESS = "OTP_VARIFY_SUCCESS";
export const OTP_VARIFY_FAILURE = "OTP_VARIFY_FAILURE";

export const RESEND_OTP_REQUEST = "RESEND_OTP_REQUEST";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAILURE = "RESEND_OTP_FAILURE";

export const TWO_FACTOR_ENABLED_OTP_REQUEST = "TWO_FACTOR_ENABLED_OTP_REQUEST";
export const TWO_FACTOR_ENABLED_OTP_SUCCESS = "TWO_FACTOR_ENABLED_OTP_SUCCESS";
export const TWO_FACTOR_ENABLED_OTP_FAILURE = "TWO_FACTOR_ENABLED_OTP_FAILURE";

export const COUNTRYLIST_REQUEST = "COUNTRYLIST_REQUEST";
export const COUNTRYLIST_SUCCESS = "COUNTRYLIST_SUCCESS";
export const COUNTRYLIST_FAILURE = "COUNTRYLIST_FAILURE";

export const PASSWORD_RESET_REQUEST = "PASSWORD_RESET_REQUEST";
export const PASSWORD_RESET_SUCCESS = "PASSWORD_RESET_SUCCESS";
export const PASSWORD_RESET_FAILURE = "PASSWORD_RESET_FAILURE";

export const NUMBER_ADD_REQUEST = "NUMBER_ADD_REQUEST";
export const NUMBER_ADD_SUCCESS = "NUMBER_ADD_SUCCESS";
export const NUMBER_ADD_FAILURE = "NUMBER_ADD_FAILURE";

export const NUMBER_ACTIVATE_REQUEST = "NUMBER_ACTIVATE_REQUEST";
export const NUMBER_ACTIVATE_SUCCESS = "NUMBER_ACTIVATE_SUCCESS";
export const NUMBER_ACTIVATE_FAILURE = "NUMBER_ACTIVATE_FAILURE";

export const PRIMARY_PHONE_REQUEST = "PRIMARY_PHONE_REQUEST";
export const PRIMARY_PHONE_SUCCESS = "PRIMARY_PHONE_SUCCESS";
export const PRIMARY_PHONE_FAILURE = "PRIMARY_PHONE_FAILURE";

export const PRIMARY_CARD_REQUEST = "PRIMARY_CARD_REQUEST";
export const PRIMARY_CARD_SUCCESS = "PRIMARY_CARD_SUCCESS";
export const PRIMARY_CARD_FAILURE = "PRIMARY_CARD_FAILURE";

export const NUMBER_DEACTIVATE_REQUEST = "NUMBER_DEACTIVATE_REQUEST";
export const NUMBER_DEACTIVATE_SUCCESS = "NUMBER_DEACTIVATE_SUCCESS";
export const NUMBER_DEACTIVATE_FAILURE = "NUMBER_DEACTIVATE_FAILURE";

export const CARD_ADD_REQUEST = "CARD_ADD_REQUEST";
export const CARD_ADD_SUCCESS = "CARD_ADD_SUCCESS";
export const CARD_ADD_FAILURE = "CARD_ADD_FAILURE";

export const CARD_EDIT_REQUEST = "CARD_EDIT_REQUEST";
export const CARD_EDIT_SUCCESS = "CARD_EDIT_SUCCESS";
export const CARD_EDIT_FAILURE = "CARD_EDIT_FAILURE";

export const CARD_REMOVE_REQUEST = "CARD_REMOVE_REQUEST";
export const CARD_REMOVE_SUCCESS = "CARD_REMOVE_SUCCESS";
export const CARD_REMOVE_FAILURE = "CARD_REMOVE_FAILURE";

export const CARD_LIST_REQUEST = "CARD_LIST_REQUEST";
export const CARD_LIST_SUCCESS = "CARD_LIST_SUCCESS";
export const CARD_LIST_FAILURE = "CARD_LIST_FAILURE";

export const CARD_ACTIVATE_REQUEST = "CARD_ACTIVATE_REQUEST";
export const CARD_ACTIVATE_SUCCESS = "CARD_ACTIVATE_SUCCESS";
export const CARD_ACTIVATE_FAILURE = "CARD_ACTIVATE_FAILURE";

export const CARD_DEACTIVATE_REQUEST = "CARD_DEACTIVATE_REQUEST";
export const CARD_DEACTIVATE_SUCCESS = "CARD_DEACTIVATE_SUCCESS";
export const CARD_DEACTIVATE_FAILURE = "CARD_DEACTIVATE_FAILURE";

export const DETAIL_EDIT_REQUEST = "DETAIL_ADD_REQUEST";
export const DETAIL_EDIT_SUCCESS = "DETAIL_ADD_SUCCESS";
export const DETAIL_EDIT_FAILURE = "DETAIL_ADD_FAILURE";

export const MONTHLY_STATS_REQUEST = "MONTHLY_STATS_REQUEST";
export const MONTHLY_STATS_SUCCESS = "MONTHLY_STATS_SUCCESS";
export const MONTHLY_STATS_FAILURE = "MONTHLY_STATS_FAIALURE";

export const TRANSACTIONS_LIST_REQUEST = "TRANSACTIONS_LIST_REQUEST";
export const TRANSACTIONS_LIST_SUCCESS = "TRANSACTIONS_LIST_SUCCESS";
export const TRANSACTIONS_LIST_FAILURE = "TRANSACTIONS_LIST_FAILURE";

export const PHONE_LIST_REQUEST = "PHONE_LIST_REQUEST";
export const PHONE_LIST_SUCCESS = "PHONE_LIST_SUCCESS";
export const PHONE_LIST_FAILURE = "PHONE_LIST_FAILURE";

export const PROVIDER_LIST_REQUEST = "PROVIDER_LIST_REQUEST";
export const PROVIDER_LIST_SUCCESS = "PROVIDER_LIST_SUCCESS";
export const PROVIDER_LIST_FAILURE = "PROVIDER_LIST_FAILURE";

export const ACTIVITY_SEARCH_REQUEST = "ACTIVITY_SEARCH_REQUEST";
export const ACTIVITY_SEARCH_SUCCESS = "ACTIVITY_SEARCH_SUCCESS";
export const ACTIVITY_SEARCH_FAILURE = "ACTIVITY_SEARCH_FAILURE";

export const PWDLINK_SENT_REQUEST = "PWDLINK_SENT_REQUEST";
export const PWDLINK_SENT_SUCCESS = "PWDLINK_SENT_SUCCESS";
export const PWDLINK_SENT_FAILURE = "PWDLINK_SENT_FAILURE";

export const ACTIVITY_LIST_REQUEST = "ACTIVITY_LIST_REQUEST";
export const ACTIVITY_LIST_SUCCESS = "ACTIVITY_LIST_SUCCESS";
export const ACTIVITY_LIST_FAILURE = "ACTIVITY_LIST_FAILURE";

export const COUNTRY_LIST_REQUEST = "COUNTRY_LIST_REQUEST";
export const COUNTRY_LIST_SUCCESS = "COUNTRY_LIST_SUCCESS";
export const COUNTRY_LIST_FAILURE = "COUNTRY_LIST_FAILURE";

export const COUNTRY_STATES_REQUEST = "COUNTRY_STATES_REQUEST";
export const COUNTRY_STATES_SUCCESS = "COUNTRY_STATES_SUCCESS";
export const COUNTRY_STATES_FAILURE = "COUNTRY_STATES_FAILURE";
