import { Typography, useMediaQuery, useTheme } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Slide from "@material-ui/core/Slide";
import React, { useState } from "react";
import NumberFormat from "react-number-format";
import signUp_img from "assets/images/home_content_5.png";
import Timer from "components/timer";
import ItemList from "components/public-dashboard/components/ItemList";
import VerificationHeader from "./header";
import MaskedVerificationCode from "./masked_code";
import Grid from "@material-ui/core/Grid";
import { sendEmailOtp, twoFactorEnabled } from "../../../../actions/auth";
import {
  RESEND_OTP_FAILURE,
  RESEND_OTP_SUCCESS,
  TWO_FACTOR_ENABLED_OTP_FAILURE,
  TWO_FACTOR_ENABLED_OTP_SUCCESS,
} from "../../../../constants/actionTypes";
import { useDispatch } from "react-redux";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function SelectOTPType(props) {
  const theme = useTheme();
  const { title, title2, onMethodSelected } = props;
  const md = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <VerificationHeader
      title={title}
      color="#fbb004"
      title2={title2}
      color2="#fd7900"
      titleLg="h3"
      titleSm="h4"
      titleXs="h5"
      list={
        <ItemList
          items={[
            <Typography variant={md ? "h6" : "body1"}>
              Select how your &nbsp;<b>6 digit code</b>&nbsp; OTP code will be
              sent to you: <br />
            </Typography>,
            <Grid container spacing={1}>
              <Grid item xs={12} md={3} spacing={3}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => onMethodSelected("sms")}
                  // style={{ borderRadius: 20 }}
                >
                  SMS
                </Button>
              </Grid>

              <Grid item xs={12} md={3} spacing={3}>
                <Button
                  type="submit"
                  color="secondary"
                  variant="contained"
                  onClick={() => onMethodSelected("email")}

                  // style={{ borderRadius: 20 }}
                >
                  EMAIL
                </Button>
              </Grid>
            </Grid>,
          ]}
        />
      }
      img={signUp_img}
    />
  );
}

function EnterVerificationCode(props) {
  const {
    title,
    title2,
    handleChange,
    mobile,
    resendOtp,
    error,
    selectedOTPMethod,
    email,
    timeoutDuration,
    useDifferentVerificationMethod,
  } = props;
  const theme = useTheme();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const [code, setCode] = useState("");
  const maxTime = timeoutDuration * 60;
  const [seconds, setSeconds] = useState(maxTime);

  return (
    <VerificationHeader
      title={title}
      color="#fbb004"
      title2={title2}
      color2="#fd7900"
      titleLg="h3"
      titleSm="h4"
      titleXs="h5"
      list={
        <ItemList
          items={[
            <Typography variant={md ? "h6" : "body1"}>
              Enter the &nbsp;<b>6 digit code</b>&nbsp; sent to your&nbsp;
              {selectedOTPMethod === "sms" ? "mobile number" : "email"}: <br />
              {selectedOTPMethod === "sms" && (
                <NumberFormat
                  value={mobile}
                  format="#(###) ###-####"
                  displayType={"text"}
                />
              )}
              {selectedOTPMethod === "email" && email}
            </Typography>,
            <MaskedVerificationCode
              error={error}
              value={code}
              handleChange={(e) => {
                setCode(e.target.value);
                handleChange(e);
              }}
            />,
            <Timer
              useDifferentVerificationMethod={useDifferentVerificationMethod}
              resendOtp={resendOtp}
              seconds={seconds}
              setSeconds={setSeconds}
              maxTime={maxTime}
            />,
            <Button
              type="submit"
              color="primary"
              variant="contained"
              // style={{ borderRadius: 20 }}
              id="submitSms"
              disabled={seconds === 0}
            >
              Submit
            </Button>,
          ]}
        />
      }
      img={signUp_img}
    />
  );
}

export default function SmsVerificationDialog(props) {
  const {
    open,
    handleClose,
    setMobileOtp,
    setOtpType,
    smsOnly,
    onOtpVarify,
    email,
    mobile,
    timeoutDuration,
    sendSMSOtp,
    sendEmailOtp,
  } = props;
  const theme = useTheme();
  const dispatch = useDispatch();
  const md = useMediaQuery(theme.breakpoints.up("md"));
  const maxTime = 120;
  const [selectedOTPMethod, setSelectedOTPMethod] = useState(
    smsOnly ? "sms" : "email"
  );

  function handleChange(e) {
    setMobileOtp(e.target.value);
  }

  function onSubmit(e) {
    e.preventDefault();
    onOtpVarify();
  }

  function onClose() {
    handleClose();
  }

  function onMethodSelected(value) {
    setSelectedOTPMethod(value);
    setOtpType(value);
    if (value === "email") {
      sendEmailOtp(email, mobile);
    } else {
      sendSMSOtp(mobile);
    }
  }

  function useDifferentVerificationMethod() {
    setSelectedOTPMethod("");
  }

  return (
    <Dialog
      open={open}
      aria-labelledby="form-dialog-title"
      TransitionComponent={Transition}
      keepMounted
      maxWidth={"md"}
    >
      <DialogContent>
        <form
          onSubmit={onSubmit}
          style={{ padding: md ? theme.spacing(3) : 0 }}
        >
          {selectedOTPMethod === "" && (
            <SelectOTPType {...props} onMethodSelected={onMethodSelected} />
          )}
          {selectedOTPMethod !== "" && (
            <EnterVerificationCode
              {...props}
              selectedOTPMethod={selectedOTPMethod}
              handleChange={handleChange}
            />
          )}
        </form>
      </DialogContent>
      <DialogActions>
        {selectedOTPMethod !== "" && (
          <Button onClick={useDifferentVerificationMethod} color="secondary">
            Try Another Way
          </Button>
        )}
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
}
